<template>
    <div class="col-12">
        <template v-if="notEnoughData">
            <!--Error-->
            <error></error>
            <p v-if="notEnoughData" class="alert alert-danger mt-5 text-center">{{$t('not-enough-data')}}</p>
        </template>
        <div v-else>
            <form @submit.prevent="submit" novalidate class="mt-5">
                <bank-select v-if="processorName == 'LUQAPAY'" requestPath="luqapay/availablePayoutBanks" @selectedBank="selectedLuqaPayBank"></bank-select>

                <div class="form-group floating-input mt-3 input-group">
                    <input type="number" class="form-control" id="withdrawAmount" v-model.trim="$v.amount.$model" :placeholder="$t('amount')" required>
                    <label for="withdrawAmount">{{$t('amount')}}</label>

                    <div class="input-group-append">
                        <span class="input-group-text">{{playerCurrency}}</span>
                    </div>
                </div>
                <div class="validation-error">
                    <label v-if="!$v.amount.required && $v.amount.$dirty">{{$t('please-enter-your', { field: $t('amount') })}}</label>
                    <label v-else-if="!$v.amount.numeric && $v.amount.$dirty">{{$t('not-a-number')}}</label>
                    <label v-else-if="!$v.amount.minValue && $v.amount.$dirty">{{$t('min-amount', { value: minWithdrawal}) }}</label>
                    <label v-else-if="!$v.amount.maxValue && $v.amount.$dirty">{{$t('max-amount', { value: maxWithdrawal}) }}</label>
                </div>

                <template v-if="walletType == 'PAYFIX'">
                    <div class="form-group floating-input mt-3">
                        <input type="text" class="form-control" id="customerName" v-model.trim="$v.customerName.$model" :placeholder="$t('customer-name')" required>
                        <label for="customerName">{{$t('customer-name')}}</label>
                    </div>
                    <div class="validation-error">
                        <label v-if="!$v.customerName.required && $v.customerName.$dirty">{{$t('please-enter-your', { field: $t('customer-name') })}}</label>
                    </div>

                    <div class="form-group floating-input mt-3">
                        <input type="text" class="form-control" id="customerIdentificationNumber-payfix" v-model.trim="$v.customerIdentificationNumber.$model" :placeholder="$t('customer-identification-number-payfix')" required>
                        <label for="customerIdentificationNumber-payfix">{{$t('customer-identification-number-payfix')}}</label>
                    </div>
                    <div class="validation-error">
                        <label v-if="!$v.customerIdentificationNumber.required && $v.customerIdentificationNumber.$dirty">{{$t('please-enter-your', { field: $t('customer-identification-number-payfix') })}}</label>
                    </div>
                </template>
                
                <template v-if="walletType == 'MEFETE'">
                    <div class="form-group floating-input mt-3">
                        <input type="text" class="form-control" id="customerIdentificationNumber-mefete" v-model.trim="$v.customerIdentificationNumber.$model" :placeholder="$t('customer-identification-number-mefete')" required>
                        <label for="customerIdentificationNumber-mefete">{{$t('customer-identification-number-mefete')}}</label>
                    </div>
                    <div class="validation-error">
                        <label v-if="!$v.customerIdentificationNumber.required && $v.customerIdentificationNumber.$dirty">{{$t('please-enter-your', { field: $t('customer-identification-number-mefete') })}}</label>
                    </div>

                    <div class="form-group floating-input mt-3">
                        <input type="text" class="form-control" id="accountNumber-mefete" v-model.trim="$v.address.$model" :placeholder="$t('account-number-mefete')" required>
                        <label for="accountNumber-mefete">{{$t('account-number-mefete')}}</label>
                    </div>
                    <div class="validation-error">
                        <label v-if="!$v.address.required && $v.address.$dirty">{{$t('please-enter-your', { field: $t('account-number-mefete') })}}</label>
                    </div>
                </template>

                <template  v-if="walletType == 'BANKTRANSFER'">
                    <div class="form-group floating-input mt-3" >
                        <input type="text" class="form-control" id="iban" v-model.trim="$v.iban.$model" :placeholder="$t('iban')" required>
                        <label for="iban">{{$t('iban')}}</label>
                    </div>
                    <div class="validation-error">
                        <label v-if="!$v.iban.required && $v.iban.$dirty">{{$t('please-enter-your', { field: $t('iban') })}}</label>
                    </div>

                    <template v-if="processorName == 'ASTURKEY'">
                        <div class="form-group floating-input mt-3">
                            <input type="text" class="form-control" id="bankName" v-model.trim="$v.bankName.$model" :placeholder="$t('bank-name')" required>
                            <label for="bankName">{{$t('bank-name')}}</label>
                        </div>
                        <div class="validation-error">
                            <label v-if="!$v.bankName.required && $v.bankName.$dirty">{{$t('please-enter-your', { field: $t('bank-name') })}}</label>
                        </div>
                    </template>
                </template>

                <template v-if="walletType == 'EPARA' || walletType == 'PAPARA' || walletType == 'ANIDA'">
                    <div class="form-group floating-input mt-3">
                        <input type="text" class="form-control" id="accountNumber-epara" v-model.trim="$v.address.$model" :placeholder="$t('account-number-epara')" required>
                        <label for="accountNumber-epara">{{$t('account-number-epara')}}</label>
                    </div>
                    <div class="validation-error">
                        <label v-if="!$v.address.required && $v.address.$dirty">{{$t('please-enter-your', { field: $t('account-number-epara') })}}</label>
                    </div>
                </template>

                <button class="btn btn-success col-12 mt-3" :class="{'disabled': submitStatus === 'PENDING'}">
                    <div v-if="submitStatus !== 'PENDING'"><strong>{{$t('submit')}}</strong></div>
                    <div v-else class="d-flex align-items-center">
                        <strong>{{$t('loading')}}...</strong>
                        <div class="spinner-border spinner-border-sm ml-auto" role="status" aria-hidden="true"></div>
                    </div>
                </button>

                <div>
                    <label v-if="submitStatus === 'ERROR'" class="validation-error">{{$t('request-unsuccessful')}}</label>
                    <label v-if="submitStatus === 'OK'" class="validation-success">{{$t('request-successful')}}</label>
                </div>
                
                <p class="text-center mt-2 d-block">{{$t('payout-note')}}</p>
            </form>
        </div>
    </div>
</template>

<script>
import store from '../../../store/shared_state';
import {withdraw} from '../../../services/backend.service';
import {validationMixin} from 'vuelidate';
import {required, numeric, minValue, maxValue} from 'vuelidate/lib/validators';

export default {
    name: 'Withdraw',
    data() {
        return {
            notEnoughData: null,
            processorName: null,
            customerId: null,
            processorType: null,
            walletType: null,
            ipAddress: null,
            payoutToken: null,
            playerCurrency: null,
            minWithdrawal: null,
            maxWithdrawal: null,
            amount: null,
            customerName: null,
            customerIdentificationNumber: null,
            address: null,
            iban: null,
            bankName: null,
            selectedBankCode: null,
            submitStatus: null,
        }
    },
    components: {
        Error: () => import('../../others/Error'),
        BankSelect: () => import('../../others/BankSelect'),
    },
    mixins: [validationMixin],
    validations() {
        let validators = {
            amount: {
                required,
                numeric,
                minValue: minValue(this.minWithdrawal),
                maxValue: maxValue(this.maxWithdrawal)
            },
            address: {},
            customerName: {},
            customerIdentificationNumber: {},
            iban: {},
            bankName: {},
        };

        if(this.walletType == 'PAYFIX') validators.customerName = { required };

        if(this.walletType == 'PAYFIX' || this.walletType == 'MEFETE') validators.customerIdentificationNumber = { required };

        if(this.walletType == 'MEFETE' || this.walletType == 'EPARA' || this.walletType == 'PAPARA' || this.walletType == 'ANIDA') validators.address = { required };

        if(this.walletType == 'BANKTRANSFER') validators.iban = { required };

        if(this.walletType == 'BANKTRANSFER' && this.processorName == 'ASTURKEY') validators.bankName = { required };
        
        return validators;
    },
    computed: {
        tokenData() { return store.state.tokenData },
    },
    mounted() {
        const tokenData = this.tokenData;

        console.log('-----STORE DATA-----')
        console.log(tokenData)

        if(tokenData && tokenData.processorName && tokenData.customerId && tokenData.walletType && tokenData.ipAddress && tokenData.payoutToken && tokenData.currencyCode &&
          tokenData.extra && tokenData.extra.min && tokenData.extra.max) {
            this.processorName = tokenData.processorName;
            this.customerId = tokenData.customerId;
            this.processorType = 'EWALLET';
            this.walletType = tokenData.walletType;
            this.ipAddress = tokenData.ipAddress;
            this.payoutToken = tokenData.payoutToken;
            this.playerCurrency = tokenData.currencyCode;
            this.minWithdrawal = tokenData.extra.min;
            this.maxWithdrawal = tokenData.extra.max;
        } else {
            this.notEnoughData = true;
        }
    },
    methods: {
        selectedLuqaPayBank(bank){
            this.selectedBankCode = bank.swiftCode;
        },
        submit() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                // this.submitStatus = 'ERROR';
            } else {
                this.submitStatus = 'PENDING';

                let data = {
                    customerId: this.customerId,
                    processorName: this.processorName,
                    processorType: this.processorType,
                    walletType: this.walletType,
                    ipAddress: this.ipAddress,
                    currency: this.playerCurrency,
                    // Backend is working with cents so we need to pass cents hence * 100
                    amount: this.amount * 100,
                    payoutToken: this.payoutToken,
                };

                if(this.selectedBankCode) data.swiftCode = this.selectedBankCode;
                if(this.address) data.accountNumber = this.address;
                if(this.customerName) data.customerName = this.customerName;
                if(this.customerIdentificationNumber) data.customerIdentificationNumber = this.customerIdentificationNumber;
                if(this.iban) data.IBAN = this.iban;
                if(this.bankName) data.bankName = this.bankName;
                
                withdraw(data).then((res) => {
                    if(res.data.success && res.data.data.status !== "ERROR"){
                        this.submitStatus = 'OK';
                    } else {
                        this.submitStatus = 'ERROR';
                    }
                }).catch( e => {
                    this.submitStatus = 'ERROR';
                    console.log(`%c Error in Withdraw.vue - method: submit - ${e}`, 'color:red');
                });
            }
        }
    },
}
</script>